import React from "react";
import Layout from "../../../components/Layout/Layout";

const ArticleHP = () => {
  const [pathname, setPathname] = React.useState("");

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setPathname(window.location.pathname);
    }
  }, []);
  return (
    <Layout>
      <iframe
        src={`https://${pathname?.substring(
          pathname?.indexOf("ArticleHP/") + 10,
        )}`}
        style={{
          height: "3000px",
          width: "100%",
          border: "none",
          paddingTop: "200px",
        }}
      ></iframe>
    </Layout>
  );
};

export default ArticleHP;
